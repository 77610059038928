/**
 * Función extraida de la librería D1C
 */

export function getparentFormFromSubmitButton() {
  window.DFI.getparentFormFromSubmitButton = function (IdSubmitButtonId) {
    if (IdSubmitButtonId === undefined) {
      return undefined
    }
    // Número de intentos que se permite a la función en buscar el parent form desde el botón donde se hace el submit
    const deepAllowed = 17
    let elementDOM = IdSubmitButtonId
    for (let i = 0; i < deepAllowed; i++) {
      if (
        elementDOM !== null &&
        elementDOM.parentElement !== null &&
        elementDOM.parentElement.tagName === 'FORM'
      ) {
        return elementDOM.parentElement
      }
      if (elementDOM.parentElement !== null) {
        elementDOM = elementDOM.parentElement
      }
    }

    return undefined
  }
}
