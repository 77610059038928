/**
 * Esta función manipula las acciones que se deben ejecutar al momento de hacer submit en un formulario que genera un lead
 * Tambien implementa el evento click en los elementos con clase: .sendBttn para realizar los antes mencionado.
 */

import { TAGS_DICTIONARY } from '../config'
import { pickElementAll } from '../utils'
import { getFormFields } from './getFormFields'
import { getValueSexTag } from './getValueSexTag'
import { setCanalTag } from './setCanalTag'
import { setTagsFromLeadConfig } from './setTagsFromLeadConfig'

/**
 * Esta funcion se encarga de gestionar el valor de los checkbox en los formularios
 * para implementar lo en delio.
 */

function getCheckBoxDataValue() {
  let delio_opt_in_marketing   =  document.getElementById("delio_opt_in_marketing");
  let delio_opt_in_marketing2  =  document.getElementById("delio_opt_in_marketing2");
  let delio_opt_in_marketing3  =  document.getElementById("delio_opt_in_marketing3");

  return {
    opt_in_marketing   :  delio_opt_in_marketing ? delio_opt_in_marketing.checked : undefined,
    opt_in_marketing2  :  delio_opt_in_marketing2 ? delio_opt_in_marketing2.checked : undefined,
    opt_in_marketing3  :  delio_opt_in_marketing3 ? delio_opt_in_marketing3.checked : undefined,
  }
}

export function handleSubmit() {
  const submitBtns = pickElementAll('.sendBttn')

  submitBtns.forEach((btn) => {
    btn.removeAttribute('onclick')
    btn.addEventListener(
      'click',
      (event) => {
        window.DFI.labels.EDeclaratives = {}
        window.DFI.labels.EConversion = []

        if (window.$('#form_delio')[0].reportValidity()) {
          window.updateData()
          window.printSlide('send')
          window.sendEvent(
            'lead_create',
            window.$("[id='cmb_send']").text(),
            window.leadConfig.declarativeData.tipo_formulario
          )

          const { declarativeData } = window.leadConfig
          Object.assign(window.datalayerCaser, declarativeData)

          const checkboxData = getCheckBoxDataValue();
          Object.assign(window.datalayerCaser, checkboxData);

          const { conversion, visit } = TAGS_DICTIONARY
          setTagsFromLeadConfig(conversion, 'EConversion', 'conversionData')
          setTagsFromLeadConfig(visit, 'EVisits', 'visitData')

          setCanalTag()

          getValueSexTag()

          getFormFields(event)

          window.DFI.addLead(window.datalayerCaser, (data) => {
            const { leadId, result } = data
            window.envioLead(result, leadId)
            console.log('Respuesta Add Lead --> ', data.result)
          })
        }
      },
      true
    )
  })
}
