/**
 * Funciones dedicadas al seteo de etiquetas para los leads que se hacen a través del ringpool
 */

import { TAGS_DICTIONARY } from '../config'

function handleConversionTags() {
  const createConversionTags = Object.entries(TAGS_DICTIONARY.conversion)
    .map(([key, value]) => {
      return window.leadConfig.conversionData[value]
        ? {
            id: Number(key.split('_').at(2)),
            value: window.leadConfig.conversionData[value]
          }
        : null
    })
    .filter((tag) => tag !== null)

  return [
    ...createConversionTags,
    {
      id: 6,
      value: 'recepcion'
    }
  ]
}

/**
 * Esta funcion gestiona la integracion mediante setinterval de gestionar 
 * el seteo de las etiquetas para los leads. Lo cual se ejecuta o no siempre 
 * que se cumpla la condición.
 */
export function updateTagsForDRPLeads() {
  const waitDRP = setInterval(() => {
    if (
      typeof DRP !== 'undefined' &&
      window.DRP.ringPoolCompleted &&
      typeof window.DFI !== 'undefined' &&
      typeof window.DFI.labels !== 'undefined'
    ) {      
      clearInterval(waitDRP)
      console.log('cargando etiquetas')

      // Mantenemos las etiquetas anterior y añadimos las etiquetas que nos pasa Caser
      window.DFI.labels.EDeclaratives = {
        ...window.DFI.labels.EDeclaratives,
        ...window.leadConfig.declarativeData
      }
      window.DFI.labels.EDeclaratives.url_alta = window.location.href;
      window.DFI.labels.EConversion = handleConversionTags()
    
      window.DRP.loadLabels(window.DFI.labels)
    }
  }, 500)
}
