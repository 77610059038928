/**
 * Función que encapsula la creación e inicialización del observador
 * Crea un observador para cambios de atributos específicamente para
 * elementos con la clase 'cmb_send' en formularios callme, cuando el botón submit tiene el id => #cmb_send
 */

import { handleSubmit } from "../../helpers/handleSubmit";



export function startCallMeObserver() {
  let timeoutId; // Definir timeoutId fuera del bloque try

  try {
    // Verificar si el elemento objetivo ya tiene la clase 'sendBttn'
    const targetObserver = document.querySelector("#cmb_send");
    if (!targetObserver) {
      console.warn("El elemento objetivo #cmb_send no se encontró en el DOM.");
      return false;
    }
    
    const { classList } = targetObserver;
    const tieneClaseSendBttn = classList.contains("sendBttn");

    if (tieneClaseSendBttn) {
      // La clase 'sendBttn' ya está presente, no es necesario iniciar el observador

      handleSubmit();
      return true;
    }

    const callBackObserver = (mutations, observer) => {
      for (const mutation of mutations) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const { classList } = mutation.target;
          const tieneClaseSendBttn = classList.contains("sendBttn");


          // Si el elemento tiene la clase 'sendBttn', detiene la observación
          if (tieneClaseSendBttn) {
            handleSubmit();
            observer.disconnect();
            clearTimeout(timeoutId); // Limpiar el temporizador ya que se encontró la clase
            return true;
          }
        }
      }
      return false;
    };

    // Opciones para el observador
    const observerOptions = {
      attributes: true, // Asegurarse de establecer esto en true para observar cambios de atributos
      attributeFilter: ["class"],
      attributeOldValue: true,
      subtree: true,
    };

    // Crear el observador con la función de retorno de llamada y opciones
    const observerCallme = new MutationObserver(callBackObserver);

    // Iniciar la observación
    observerCallme.observe(targetObserver, observerOptions);

    // Detener la observación después de 2 minutos si no se encuentra la clase 'sendBttn'
    const minutosObservador = 2;
    const tiempoObservador = minutosObservador * 60 * 1000;
    timeoutId = setTimeout(() => {
      observerCallme.disconnect();
    }, tiempoObservador);

    return true;
  } catch (error) {
    console.error("Error al iniciar el observador de CallMe:", error);
    if (timeoutId) clearTimeout(timeoutId); // Limpiar el temporizador en caso de error
    return false;
  }
}