/**
 * En este fichero está destinado para funciones cortas que pueden facilitar alguna acción sencilla
 */

/**
 * Funcion que dado un nombre de ID devuelve un selector 
 * REFACTORIZAR ESTA FUNCION , NO TIENE SENTIDO . EN CASO DE QUE NO EXISTA ESE ELEMENTO DEVUELVE NULL 
 * @param {*} element 
 * @returns elemento  
 */
export const pickElementById = (element) => document.getElementById(element)

/**
 * Funcion que dado un nombre de ID O CLASE devuelve un selector 
 * REFACTORIZAR ESTA FUNCION , NO TIENE SENTIDO . NO SE ESTA CONTROLANDO LOS CASOS CUANDO ESE ELEMENTO NO EXISTA 
 * @param {*} element 
 * @returns elemento 
 */
export const pickElementAll = (element) => document.querySelectorAll(element)

/**
 * Funcion que dado un name , crea dicho elemento 
 * 
 * @param {*} element 
 * @returns elemento
 */
export const createElement = (element) => document.createElement(element)
