/**
 * Esta es la función utilizada para generar leads extraida directamente de la librería de D1C.
 * El motivo es que en el caso de que el usuario no acepte las cookies, es necesario poder generar los leads
 * sin cargar la librería ya que esta carga el Pixel de visita.
 */

import { CASER_VARS } from '../config'

export function addLead() {
  window.DFI.addLead = function (json, fn) {
    const declaratives = window.DFI.labels.EDeclaratives
    const conversion = window.DFI.labels.EConversion

    window.DFI.form = {}
    window.DFI.form.submitted = false

    let ws =
      'https://ws.walmeric.com/provision/' + 'wsclient/client_addlead.html'

    const eConversions = []

    for (const property in conversion) {
      const indice = property.replace('c_var_', '')
      eConversions.push({
        id: +indice,
        value: conversion[property]
      })
      window[property] = conversion[property]
    }
    const encode = encodeURIComponent(JSON.stringify(eConversions))

    ws += '?'
    ws += 'format=json'
    ws += '&idTag=' + CASER_VARS.idTagDelio
    ws += '&idlead=' + ''
    if (window.DFI.setVerifyLeadid !== 'SI') {
      ws += '&verifyLeadId=NO'
    }

    for (const key in json) {
      if (json[key] !== null && json[key] !== 'null' && json[key] !== '') {
        ws += '&' + key + '=' + json[key]
        if (
          window.DFI.DFI_configuration.services.userForm !== undefined &&
          window.DFI.DFI_configuration.services.userForm.formPhoneName ===
            key &&
          window.DFI.DFI_configuration.services.userForm.formPhoneName !==
            'telefono'
        ) {
          ws += '&' + 'telefono' + '=' + json[key]
        }
      }
    }

    for (const key in declaratives) {
      ws += '&' + key + '=' + declaratives[key]
    }

    ws += '&EConversion=' + encode

    const params = {
      url: ws,
      type: 'jsonp'
    }

    window.DAL.Ajax(params).done(function (data) {
      window.DFI.form.submitted = true
      if (data.result === 'OK') {
        window.DFI.form.result = true

        if (fn) {
          fn(data)
        }
      } else if (fn) {
        window.DFI.form.result = false
        fn(data)
      }
    })
  }
}
