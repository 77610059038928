/**
 * Setea la etiqueta canal para el caso de un lead a través de un formulario
 */

import { pickElementById } from '../utils'

export function setCanalTag() {
  const dropDownCallsSchedule = pickElementById('horario_llamadasDiv')

  window.DFI.labels.EConversion.c_var_6 = 'callme_now'

  if (dropDownCallsSchedule.style.display !== 'none') {
    dropDownCallsSchedule.addEventListener('change', (event) => {
      if (event.target.value === 'ahora') {
        window.DFI.labels.EConversion.c_var_6 = 'callme_back'
      }
    })
  }
}
