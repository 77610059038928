/**
 * Aquí se definen las varibles y la configuración global que usa el script del cliente
 */

export const CASER_VARS = {
  paths: {
    D1C: 'https://t.womtp.com/slider/c/delio-lm/dfi/init.js',
    DAL: 'https://ws.walmeric.com/static/dft/dist/v1/dal.js',
    enviromentTest: 'https://t.womtp.com/slider/d/caser/satellite.js'
  },
  idTagDelio:
    '29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a043d404cbd18bd6dba6b79aea2a65900dd3f6c9cd93959b90bca0b3eb16d640a9e4c68da82e95adc38b58ec2f1080030627e11ae7f9a5b182fbe140d558cc92485c68082a3adec3788502a26e31a7188'
}

export const TAGS_DICTIONARY = {
  conversion: {
    c_var_1: 'gestor',
    c_var_2: 'origen',
    c_var_3: 'solicita_desde',
    c_var_4: 'categoria',
    c_var_7: 'objetivo',
    c_var_9: 'mercado',
    c_var_12: 'page_url',
    c_var_13: 'modalidad'
  },
  visit: {
    v_var_1: 'wmatch',
    v_var_9: 'categoria',
    v_var_10: 'site_section',
    v_var_14: 'page_name'
  }
}
