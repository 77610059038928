/**
 * Funcion que se encarga de adquirir los valores de consent del dataLayer 
 * y devuelve una promesa.
 * @param {*} dataLayer 
 * @returns getConsentValues
 */
function getConsentValues (dataLayer){
    return new Promise((resolve, reject) => {
      let counter = 0;
      const MAX_COUNTER = 20;
      let lastConsentValues = null;
      const checkConsentValues = () => {
        counter++;
        for (let i = dataLayer.length - 1; i >= 0; i--) {
          const item = dataLayer[i];
          if (item[0] === "consent") {
            const consentValues = {
              ad_storage: item[2].ad_storage ?? "",
              analytics_storage: item[2].analytics_storage ?? "",
              ad_personalization: item[2].ad_personalization ?? "",
              ad_user_data: item[2].ad_user_data ?? "",
            };
            lastConsentValues = consentValues;
            resolve(lastConsentValues);
            return;
          }
        }
        if (counter > MAX_COUNTER) {
          reject(
            new Error(
              "Error: Maximum number of attempts for ConsentMode exceeded."
            )
          );
          return;
        }
        // Checkeamos despues de 1000
        setTimeout(checkConsentValues, 1000);
        };
        // iniciamos primer check
        checkConsentValues();
    });
};

/**
 * Funcion encagarda de resolver la promesa devuelta por la funcion getConsentValues,
 * una vez resuelta la promesa inserta los datos en el array de objetos de DFI.labels.EDeclaratives
 * con su correspondiente clave -> valor
 */
export async function obtenerConsentimientoYGuardarEtiquetas() {

    try {

        const consentValues = await getConsentValues(window.dataLayer);
        // Manejar aquí los valores de consentimiento resueltos
        window.leadConfig.declarativeData.ad_storage = consentValues.ad_storage;
        window.leadConfig.declarativeData.analytics_storage = consentValues.analytics_storage;
        window.leadConfig.declarativeData.ad_personalization = consentValues.ad_personalization;
        window.leadConfig.declarativeData.ad_user_data = consentValues.ad_user_data;
        window.leadConfig.declarativeData.functionality_storage = consentValues.functionality_storage;
      
    } catch (error) {
        // Manejar cualquier error aquí
        console.error(error);
    }
}