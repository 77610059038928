/**
 * Setea las etiquetas que se encuentran en el leadConfig con el fin de  introducirlas al dataLayerCaser
 */

export function setTagsFromLeadConfig(tagsType, labelsType, leadConfigData) {
  const tags = Object.entries(tagsType)

  tags.forEach((property) => {
    const [idTag, nameTag] = property
    window.DFI.labels[labelsType][idTag] =
      window.leadConfig[leadConfigData][nameTag]
  })
}
