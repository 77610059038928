/**
 * Esta función se encarga de manipular el valor de la etiqueta de sexo para que el valor seleccionado por el usuario sea el que llegue a Delio
 */

import { pickElementAll, pickElementById } from '../utils'

export function getValueSexTag() {
  const sexFieldParent = pickElementById('sexoDiv')
  const inputsForSexField = pickElementAll('input[name="delio_sexo"]')
  window.tagForSexValue = ''

  if (sexFieldParent.style.display !== 'none') {
    inputsForSexField.forEach((input) => {
      input.addEventListener('change', (event) => {
        window.tagForSexValue = event.target.value
      })
    })
  } else {
    inputsForSexField.forEach((input) => input.removeAttribute('value'))
  }
}
