/**
 * Función extraida directamente de D1C, se usa para recuperar los datos introducidos por el usuario en los formularios
 */

export function getElementsFromForm() {
  window.DFI.getElementsFromForm = function (IdForm) {
    const outputElements = {}
    let elements = IdForm.getElementsByTagName('input')

    for (let i = 0; i < elements.length; i++) {
      if (
        IdForm.getElementsByTagName('input')[i].getAttribute('name') !== null
      ) {
        outputElements[
          IdForm.getElementsByTagName('input')[i].getAttribute('name')
        ] = IdForm.getElementsByTagName('input')[i].value
        if (
          IdForm.getElementsByTagName('input')[i].type === 'checkbox' &&
          IdForm.getElementsByTagName('input')[i].value === ''
        ) {
          outputElements[
            IdForm.getElementsByTagName('input')[i].getAttribute('name')
          ] = IdForm.getElementsByTagName('input')[i].checked
        }
      }
    }

    elements = IdForm.getElementsByTagName('select')
    for (let i = 0; i < elements.length; i++) {
      if (
        IdForm.getElementsByTagName('select')[i].getAttribute('name') !== null
      ) {
        outputElements[
          IdForm.getElementsByTagName('select')[i].getAttribute('name')
        ] =
          IdForm.getElementsByTagName('select')[i].options[
            IdForm.getElementsByTagName('select')[i].selectedIndex
          ].value
      }
    }

    return outputElements
  }
}
