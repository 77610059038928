export function changeLaunchClient(){
  window.DFI = window.DFI || {};
  // Intento parar la ocupacion del ringpool o cualquier servicio de drp
  window.DFI.launchClientNew = function(data) {
    let DFI = window.DFI;
    
    data.services.serviceList.ringPool.active = false
    data.ringPoolidServiceConditions.forEach((element)=>{
      element.active = false;
    })
    globalThis.DFI.global.drp = "";
    globalThis.DFI.global.requestRingPoolNumber = ""; 
    DFI.DFI_configuration = data;
    
    let version = DFI.DFI_configuration.version;		
    // Lanzar script para la versión del cliente
    var script = document.createElement("script");
    script.type = "text/javascript";
  
  
    // Elección de la versión del archivo init
    let old_versions = ["v3"];
    if (old_versions.indexOf(version) > -1) {
      script.src = "https://t.womtp.com/slider/" + env + "/delio-lm/dfi/init_old.js";
    } else {
      script.src = "https://t.womtp.com/slider/c" +  "/delio-lm/dfi/" + version + "/client.js";
    }
  
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  };
  
  
  
  
  
  
  // Guardar la referencia original de Node.appendChild
  const originalAppendChild = Node.prototype.appendChild;
  
  Node.prototype.appendChild = function(node) {
      if (node.tagName === 'SCRIPT') {
          if (node.src && node.src.includes('&callback=DFI.launchClient')) {
  
              // Cambiar el src del script a DFI.launchClientNew
              const newSrc = node.src.replace('&callback=DFI.launchClient', '&callback=DFI.launchClientNew'); 
              node.src = newSrc;
  
              // Reinsertar el script en el DOM con el nuevo source
              originalAppendChild.call(this, node);
              return;
          }
      }
      // Llama a la función original para otros nodos(tipo bucle)
      return originalAppendChild.call(this, node); 
  };
}

