/**
 * En este fichero pueden estar funciones destinadas a cargar recursos como scripts y hojas de estilos, también se añade la función que carga la librería de D1C
 */

import { CASER_VARS } from "../config";
import { changeLaunchClient } from "./changeLaunchClient";
import { updateTagsForDRPLeads } from "./setTagsForDRPLeads";

/**
 * Dado un path , genera un script el cual sera insertado en el HEAD de la landing page .
 * @param path
 * @returns script
 */
export function loadScript(path) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = path;
  document.head.appendChild(script);
}

/***
 * Funcion que comprueba que el DOM este completo . Una vez este esta cargado llama a  @function loadScript(path) cuyo script a cargar
 * sera este {@link https://t.womtp.com/slider/c/delio-lm/dfi/init.js}
 */

export function loadD1C() {
  const waitForD1C = setInterval(() => {
    if (document.readyState === "complete") {
      clearInterval(waitForD1C);
      window.idTagDelio = CASER_VARS.idTagDelio;
      loadScript(CASER_VARS.paths.D1C);
    }
  });
}


/**
 * Funcion para eliminar todas las clases del ringpool
 */
function deleteClassRingpool() {
  let classDRP = document.querySelectorAll(".html-drp-phone, .drp-phone")
  classDRP.forEach((element)=>{
    element.classList.remove("html-drp-phone")
})
}


/**
 * Funcion : funcion que espera que DRP.ringPoolCompleted tenga un valor para poder parar el ringpool
 */

function ringPoolCompleted() {
  return new Promise((resolve) => {
    const startTime = Date.now();
    const TIMEOUT = 60000; // 1 minutos
    const INTERVAL = 100; // 100 ms

    const interval = setInterval(() => {
      const DRP_completed = globalThis.DRP;

      if (DRP_completed && typeof DRP_completed.ringPoolCompleted === "boolean") {
        if (DRP_completed.ringPoolCompleted) {
        
          if (typeof DRP_completed.stopRingPool === "function") {
            DRP_completed.stopRingPool();
          }
          clearInterval(interval);
          resolve(true); 
        }
      }

      if (Date.now() - startTime >= TIMEOUT) {
        clearInterval(interval);
        resolve(true); 
      }
    }, INTERVAL); // Sondeo cada 100 ms
  });
}

/**
 *  Funcion para parar el ringpool: si la variable(has_ringpool) que nos pasa caser 
 *  tiene el valor false, null o undefined paramos el ringpool.
 *  Si el parametro viene true lo iniciamos .
 */

export async function stopRingpool() {
  try {
      // Verifica si has_ringpool está definido y no es null
      if (Boolean(globalThis.quoteheader?.has_ringpool)) {
        console.log("Ringpool Cargandose ");
        updateTagsForDRPLeads()
        return;
      } else {
        changeLaunchClient()

        const interval = setInterval(() => {
          if(typeof globalThis.DFI?.global === "object"){
            globalThis.DFI.global.drp = "";
            globalThis.DFI.global.requestRingPoolNumber = ""; 
            clearInterval(interval);
          }
        });

      // Eliminamos cualquier clase de ringpool
      deleteClassRingpool()

      // Verificar: si existe ringpool paramos el servicio
      const statusRingpool = await ringPoolCompleted();

      // Los mensajes en la consola van a aparecer despues de un minuto debido a la busqueda de la funcion ringPoolCompleted.
      if (statusRingpool) {
        console.log("Ringpool parado");
        return;
      } else {
        console.log(
          "No se ha logrado parar ringPoolCompleted después de varios intentos"
        );
        return;
      }
    }
  } catch (error) {
    console.error("Error en stopRingpool:", error);
  }
}
