/**
 * Punto de entrada de la aplicación, no añadir ninguna lógica aquí, cualquier implementación nueva extraerla en un fichero y utilizarlo en el archivo app.js,
 * o en su defecto modificar las funciones existentes.
 * UNICAMENTE modificar las variables de versión y actualización.
 */

import { App } from './app'
import { CASER_VARS } from './config'
import { loadScript } from './helpers/loadScripts'

/**
 * Funcion que carga el entorno de Preproducción o Produccion dependiendo de si en la url contiene el parametro ("walmericTest=int") de lo contrario cargara 
 * App() . Si contiene el parametro(walmericTest=int) carga el entorno de Pre que esta alojado en el repo Slider en la rama D {@link https://t.womtp.com/slider/d/caser/satellite.js} 
 * */
function LOAD_ENVIROMENT() {
  const URL = window.location.href

  if (URL.includes('walmericTest=int')) {
    return loadScript(CASER_VARS.paths.enviromentTest)
  }
  App()
}

LOAD_ENVIROMENT()
