/**
 * Este fichero reune todas las funciones que tiene el script, mantenerlo lo más limpio posibleç
 *  La carpeta "functions" tiene todas las funciones extraidas directamente de la librería D1C 
 */

import {
  caserVida,
  checkCookiesForLoadPixel,
  getCookies
} from './helpers/checkCookiesForLoadPixel'

/**
 * Funcion que comprueba que si el dataLayerCaser no esta creado , genera uno . 
 * @formatCookies rrecorre el array @rawCookiesque es un array que contiene  todas las cookies que tiene dicha landing page. A estas cookies les proporciona
 * un name y un value . 
 * @consentCookie rrecorre  @formatCookies y comprueba que la cookie llamada CONSENTGMR exista . Los posibles valores que puede devolver son true o false
 * En caso de que @consentCookie devuelva true , llamamos a @checkCookiesForLoadPixel y en el caso de que devuelva false llama a @getCookies
 */
export function App() {
  if (typeof window.datalayerCaser === 'undefined') {
    window.datalayerCaser = {}
  }

  const rawCookies = document.cookie.split('; ')
  const formatCookies = rawCookies.map((cookie) => {
    const [name, value] = cookie.split('=')

    return {
      [name]: value
    }
  })

  const consentCookie = formatCookies.filter((cookie) => {
    return typeof cookie.CONSENTMGR !== 'undefined'
  })

  if (consentCookie.length === 0 && window.location.hostname != 'caservida.com') {
    checkCookiesForLoadPixel()
  } else {
    getCookies()
  }

}
