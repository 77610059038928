/**
 * Recoje el valor de los inputs introducidos por el usuario, además de quitarles el prefijo y contemplar casos
 */

export function getFormFields(event) {
  const formContent = window.DFI.getElementsFromForm(
    window.DFI.getparentFormFromSubmitButton(event.target)
  )
  const formContentCleaned = {}

  Object.entries(formContent).forEach((prop) => {
    const [key, value] = prop
    const keyWhitoutDelio_ = key.split('delio_').at(1)

    if (value.length > 0) {
      formContentCleaned[keyWhitoutDelio_] = value
    }
    if (value.length === 0 || value === 'on') {
      delete formContentCleaned[keyWhitoutDelio_]
    }
    if (keyWhitoutDelio_ === 'sexo') {
      return (formContentCleaned.sexo = window.tagForSexValue)
    }
    if (window.tagForSexValue === '') {
      delete formContentCleaned.sexo
    }
  })

  Object.assign(window.datalayerCaser, formContentCleaned)
}
