/**
 * Esta función contiene la lógica necesaria para el condicionamiento de la carga de D1C, el motivo es que
 * la ley de GDPR fue modificada y el cliente no queria considerar el pixel de visita como una cookie tecnica,
 * por lo tanto, si el usuario rechaza las cookies publicitarias, es necesario hacer leads sin cargar D1C
 */

import { CASER_VARS } from "../config";
import { addLead } from "../functions/addLead";
import { getElementsFromForm } from "../functions/getElementsFromForm";
import { getparentFormFromSubmitButton } from "../functions/getparentFormFromSubmitButton";
import { pickElementById } from "../utils";

import { loadD1C, loadScript, stopRingpool } from "./loadScripts";
import { obtenerConsentimientoYGuardarEtiquetas } from "../functions/getConsentValues";
import { startCallMeObserver } from "../functions/callme/observerCallme";

export function checkCookiesForLoadPixel() {
  const cookiesModal = pickElementById("__tealiumGDPRecModal");
  const consentSubmitBtn = pickElementById("consent_prompt_submit");
  const refuseSubmitBtn = pickElementById("consent_prompt_submit_opt_out");

  /*No entiendo por que quiere que sea igual a null REVISAR */
  /** Porque gestiona el caso de el elemento no exista */
  if (cookiesModal === null) return;

  if (consentSubmitBtn !== null) {
    consentSubmitBtn.addEventListener("click", getCookies);
  }

  if (refuseSubmitBtn != null) {
    refuseSubmitBtn.addEventListener("click", getCookies);
  }

  /**
   * Se encarga de gestionar cualquier cambio en estos elementos
   */
  const observer = new MutationObserver(() => {
    const cookiesModalOptions = pickElementById("__tealiumGDPRcpPrefs");
    const rejectAll = pickElementById("preferences_prompt_deny");
    const configPreferences = pickElementById("preferences_prompt_submit");

    if (cookiesModalOptions !== null) {
      rejectAll.addEventListener("click", () => {
        observer.disconnect();
        getCookies();
      });

      configPreferences.addEventListener("click", () => {
        observer.disconnect();
        getCookies();
      });
    }
  });
  observer.observe(document.body, { childList: true, subtree: true });
}

/**
 * Esta funcion se encarga de gestionar las cookies de la app
 */
export function getCookies() {
  window.cookieStateValue = "";

  const rawCookies = document.cookie.split("; ");
  const formatCookies = rawCookies.map((cookie) => {
    const [name, value] = cookie.split("=");

    return {
      [name]: value,
    };
  });

  const consentCookie = formatCookies.filter((cookie) => {
    return typeof cookie.CONSENTMGR !== "undefined";
  });

  if (consentCookie.length !== 0) {
    /**
     * Veririficamos si esta constante se encuentra en categorias, ya que de ser así se han rechazado las cookies
     * y esto se tiene que gestionar de manera diferente.
     */
    const PUBLI_COOKIES_REJECT = "c3:0";

    const [consent] = consentCookie;
    const consentDecode = decodeURI(consent.CONSENTMGR);
    const categories = consentDecode.split("|");

    /**
     * El condicional es TRUE en caso de que se rechacen la cookies.
     */
    if (categories.includes(PUBLI_COOKIES_REJECT)) {
      window.datalayerCaser.cookie_state = "false";

      loadScript(CASER_VARS.paths.DAL);
      window.idTagDelio = CASER_VARS.idTagDelio;

      window.DFI = {
        labels: {
          EDeclaratives: [],
          EConversion: [],
          EVisits: [],
        },
      };

      window.DFI.DFI_configuration = {
        services: {
          userForm: {
            formPhoneName: {},
          },
        },
      };
      loadD1C();
      stopRingpool();
      getElementsFromForm();
      getparentFormFromSubmitButton();
      addLead();
      startCallMeObserver();
      obtenerConsentimientoYGuardarEtiquetas();
    } else {
      /**
       * En caso de aceptar cookies el condicionar es FALSE
       * se gestiona en este ELSE
       */
      loadD1C();
      stopRingpool();
      obtenerConsentimientoYGuardarEtiquetas();
      window.datalayerCaser.cookie_state = "true";
      if (typeof window.DFI_client !== "object") window.DFI_client = {};
      startCallMeObserver();
    }
  }else{
    /**
     * En caser Vida no tienen implementado el panel de cookies con Tealium. En checkCookiesForLoadPixel() pregunta por el panel
     * de cookies Tealium y no existe por tanto llega al return y para el codigo. En getCookies() tenemos la condicion que exista 
     * CONSENTMGR y como no existe no implementa el resto del codigo. 
     * HAY que refactorizar toda esta parte ya que en Caser no importa si se han rechazado o no las cookies. Toda la tecnologia de Walmeric
     * se tiene que implementar(no tiene sentido la logica actual del codigo).
     * 
     * Esta es una solucion temporal para que caserVida contenga el init.js y que el callMe funcione.
     */
    loadD1C();
    stopRingpool();
    window.datalayerCaser.cookie_state = "true";
    if (typeof window.DFI_client !== "object") window.DFI_client = {};
  }
}

